export const reviewsData = [
  {
    id: 1,
    imageSrc: require('../Images/reviews/reviews1.jpg'),
  },
  {
    id: 2,
    imageSrc: require('../Images/reviews/reviews2.jpg'),
  },
  {
    id: 3,
    imageSrc: require('../Images/reviews/reviews3.jpg'),
  },
  {
    id: 4,
    imageSrc: require('../Images/reviews/reviews4.jpg'),
  },
  {
    id: 5,
    imageSrc: require('../Images/reviews/reviews5.jpg'),
  },
  {
    id: 6,
    imageSrc: require('../Images/reviews/reviews6.jpg'),
  },
  {
    id: 7,
    imageSrc: require('../Images/reviews/reviews7.jpg'),
  },
  {
    id: 8,
    imageSrc: require('../Images/reviews/reviews8.jpg'),
  },
  {
    id: 9,
    imageSrc: require('../Images/reviews/reviews9.jpg'),
  },
  {
    id: 10,
    imageSrc: require('../Images/reviews/reviews10.jpg'),
  },
  {
    id: 11,
    imageSrc: require('../Images/reviews/reviews11.jpg'),
  },
  {
    id: 12,
    imageSrc: require('../Images/reviews/reviews12.jpg'),
  },

];
