import './AboutProduct.css'

export const AboutProduct = () => {
  return (
    <section className='aboutProduct'>
      <ul className='aboutProduct-list'>
        <li className='aboutProduct-item item-color'>
          <img className='about-img' src={require('../../Images/aboutProduct1.jpg')}alt="Жилетка на вішаку" />
          <h4 className='aboutProduct-listTitle'>Лекала</h4>
          <p className='aboutProduct-listText'>Продумана конструкція виробів та розмірів задля зручності на будь яких параметрах та стильного силуету.</p>
        </li>
        <li className='aboutProduct-item'>
          <img className='about-img' src={require('../../Images/aboutProduct2.jpg')} alt="Дівчина в жилетці" />
          <h4 className='aboutProduct-listTitle'>Тканина</h4>
          <p className='aboutProduct-listText'>Дихаючий завдяки бавовні в складі супер-софт. М'який, шовковистий на дотик, достатньо стійкий до зминання за рахунок поліестеру та еластану.</p>
        </li>
        <li className='aboutProduct-item item-color'>
          <img className='about-img' src={require('../../Images/aboutProduct3.jpg')} alt="Жилетки на вішаку" />
          <h4 className='aboutProduct-listTitle'>Комірці</h4>
          <p className='aboutProduct-listText'>Добре укріплені та проглажені, та при цьому не втрачають м'якості і комфорту а завдяки супер софту гладенькі й приємні до шкіри. Також їх можна перегладити і змінити форму.</p>
        </li>
        <li className='aboutProduct-item '>
          <img className='about-img' src={require('../../Images/aboutProduct4.jpg')}alt="Жилетка на вішаку" />
          <h4 className='aboutProduct-listTitle'>Гумка</h4>
          <p className='aboutProduct-listText'>Якісна та м'яка гумка, що дозволяє блузці достатньо міцно триматися на плечах не передавлюючи шкіру.</p>
        </li>
        <li className='aboutProduct-item item-color'>
          <img className='about-img' src={require('../../Images/aboutProduct5.jpg')} alt="Дівчина в жилетці" />
          <h4 className='aboutProduct-listTitle'>Легкість</h4>
          <p className='aboutProduct-listText'>Фасони та елементи для відчутя легкості, свободи, комфорту під час жаркої погоди та невимушеного стилю.</p>
        </li>
       
      </ul>
    </section>
  );
};
