export const productsTypeData = [
  {
    id: 'milli',
    imageSrc: require('../Images/products/milli/milliblackMain.jpg'),
    type: 'Milli',
    price: 750,
    todayPrice: 376,
    discount: 50,
    aboutTitle:'Найпопулярніша вкорочена модель нашого магазину. Має короткі рукава та прямий вкорочений низ. Два кармани на грудях. Прямий крій сорочки відповідає сучасним трендам і спрощує підбір розміру. Виріз горловини V-подібний.',
    aboutText: (<div><p>- Тканина: супер софт; </p>
    {/* <p>- Прямий крій; </p> */}
    <p>- Made in Kharkiv. </p>
    <p>Оплата: післяплатою при отриманні або повна оплата на реквізити!</p>
    <p>Доставка: Новою поштою та Укрпоштою до відділення або кур'єром до адреси!</p></div>),
    colors: [
      '#090003',
      '#ffffff',
      '#b2d1e4',  
      ],
    colorsImg: [
      {
        id: 'milliBlack',
        img: require('../Images/products/milli/milliblackMain.jpg'),
        colorName: 'Чорний',
      },
      {
        id: 'milliWhite',
        img: require('../Images/products/milli/milliWhite.jpg'),
        colorName: 'Білий',
      },
    
      {
        id: 'milliWhiteBlue',
        img: require('../Images/products/milli/milliWhiteBlue1.jpg'),
        colorName: 'Блакитний',
      },
      {
        id: 'milliWhiteBlue',
        img: require('../Images/products/milli/milliWhiteBlue2.jpg'),
        colorName: 'Блакитний',
      },
            ],
    sizes:["S","M","L"],
    sizeTable:(<div> <table className='productSize-table'>
    <thead>
      <tr>
        <th>Розмір</th>
        <th>Обхват грудей</th>
        <th>Довжина виробу</th>
        <th>Довжина рукава</th>
        <th>Рукав від коміру</th>
         </tr>
    </thead>
    <tbody>
    <tr>
        <td>Розмір S</td>
        <td>76-86 см</td>
        <td>39 см</td>
        <td>15 см</td>
        <td>36 см</td>        
      </tr>
      <tr>
        <td>Розмір M</td>
        <td>82-92 см</td>
        <td>40 см</td>
        <td>15 см</td>
        <td>36 см</td>        
      </tr>
      <tr>
        <td>Розмір L</td>
        <td>90-98 см</td>
        <td>41 см</td>
        <td>15 см</td>
        <td>36 см</td>        
      </tr>
     
        </tbody>
  </table>
  </div>),
  },
  {
    id: 'elegance',
    imageSrc: require('../Images/products/elegance/eleganceWhiteMain.jpg'),
    type: 'Elegance',
    price: 699,
    todayPrice: 349,
    discount: 50,
    aboutTitle:'Найпопулярніша модель нашого магазину минулого літа. Блузка, що кріпиться на плечах за допомогою м`якої гумки. Завдяки своєму оригінальному фасону та способу закріплення чудово сідає на будь-які параметри тому легко підібрати розмір. Заправляючи або одягаючи на викіт можна створювати водночас різні та захоплюючо стильні силуети.',
    aboutText: (<div><p>- Тканина: супер софт; </p>
    <p>- Made in Kharkiv. </p>
    <p>Оплата: післяплатою при отриманні або повна оплата на реквізити!</p>
    <p>Доставка: Новою поштою та Укрпоштою до відділення або кур'єром до адреси!</p></div>),
     colors: [
      '#ffffff',
      '#090003',
      'red',
      'blue',
      '#b2d1e4',  
      ],
    colorsImg: [
      {
        id: 'eleganceWhite1',
        img: require('../Images/products/elegance/eleganceWhiteMain.jpg'),
        colorName: 'Білий',
      },
      {
        id: 'eleganceBlack2',
        img: require('../Images/products/elegance/eleganceBlack2.jpg'),
        colorName: 'Чорний',
      },
      {
        id: 'eleganceRed3',
        img: require('../Images/products/elegance/eleganceRed3.jpg'),
        colorName: 'Червоний',
      },
      {
        id: 'eleganceBlue4',
        img: require('../Images/products/elegance/eleganceBlue4.jpg'),
        colorName: 'Синій',
      },
      {
        id: 'eleganceWhiteBlue5',
        img: require('../Images/products/elegance/eleganceWhiteBlue5.jpg'),
        colorName: 'Блакитний',
      },
      {
        id: 'eleganceWhite6',
        img: require('../Images/products/elegance/eleganceWhite6.jpg'),
        colorName: 'Білий',
      },
      {
        id: 'eleganceBlack7',
        img: require('../Images/products/elegance/eleganceBlack7.jpg'),
        colorName: 'Чорний',
      },
      {
        id: 'eleganceWhiteBlue8',
        img: require('../Images/products/elegance/eleganceWhiteBlue8.jpg'),
        colorName: 'Блакитний',
      },
      {
        id: 'eleganceBlack9',
        img: require('../Images/products/elegance/eleganceBlack9.jpg'),
        colorName: 'Чорний',
      },
        ],
    sizes:["S","M","L","XL"],
    sizeTable:(<div> <table className='productSize-table'>
    <thead>
      <tr>
      <th>Розмір</th>
      <th>Довжина виробу</th>
      <th>Довжина рукава</th>
        <th>Обхват в плечах</th>
        <th>Обхват грудей</th>
      </tr>
    </thead>
    <tbody>
 
      <tr>
        <td>Розмір S</td>
        <td>45 см</td>
        <td>54 см</td>
        <td>74-88 см</td>
        <td>76-86 см</td>
      </tr>
      <tr>
        <td>Розмір M</td>
        <td>45 см</td>
        <td>54 см</td>
        <td>80-100 см</td>
        <td>78-94 см</td>
        </tr>
      <tr>
        <td>Розмір L</td>
        <td>46 см</td>
        <td>55 см</td>
        <td>94-104 см</td>
        <td>92-99 см</td>        
      </tr>
      <tr>
        <td>Розмір XL</td>
        <td>46 см</td>
        <td>55 см</td>
        <td>100-110 см</td>
        <td>98-104 см</td>        
      </tr>
        </tbody>
  </table>
  </div>),
  },
  {
    id: 'airShirt',
    imageSrc: require('../Images/products/airShirt/airShirtWhiteMain.jpg'),
    type: 'Air Shirt',
    price: 640,
    todayPrice: 320,
    discount: 50,
    aboutTitle:'Вкорочена сорочка з коміром-поло, має короткі ввільні рукава та прямий вкорочений низ. Прямий розширений крій сорочки відповідає сучасним трендам і спрощує підбір розміру. Завдяки своєму фасону сорочка чудово підходить на жаркий сезон, гарно освіжається повітрям.',
    aboutText: (<div><p>- Тканина: супер софт; </p>
    <p>- Made in Kharkiv. </p>
    <p>Оплата: післяплатою при отриманні або повна оплата на реквізити!</p>
    <p>Доставка: Новою поштою та Укрпоштою до відділення або кур'єром до адреси!</p></div>),
     colors: [
      '#ffffff',
      '#090003',
     
      '#b2d1e4',  
      ],
    colorsImg: [
      {
        id: 'airShirtWhite1',
        img: require('../Images/products/airShirt/airShirtWhiteMain.jpg'),
        colorName: 'Білий',
      },
      {
        id: 'airShirtBlack2',
        img: require('../Images/products/airShirt/airShirtBlack2.jpg'),
        colorName: 'Чорний',
      },
      {
        id: 'airShirtWhiteBlue3',
        img: require('../Images/products/airShirt/airShirtWhiteBlue3.jpg'),
        colorName: 'Блакитний',
      },
      {
        id: 'airShirtWhite4',
        img: require('../Images/products/airShirt/airShirtWhite4.jpg'),
        colorName: 'Білий',
      }, 
      {
        id: 'airShirtWhite5',
        img: require('../Images/products/airShirt/airShirtWhite5.jpg'),
        colorName: 'Білий',
      }, 
        ],
        sizes:["S","M","L"],
        sizeTable:(<div> <table className='productSize-table'>
        <thead>
          <tr>
            <th>Розмір</th>
            <th>Обхват грудей</th>
            <th>Довжина спереді</th>
            <th>Довжина позаду</th>
            <th>Рукав від коміру</th>
             </tr>
        </thead>
        <tbody>
        <tr>
            <td>Розмір S</td>
            <td>76-86 см</td>
            <td>36 см</td>
            <td>39 см</td>
            <td>55 см</td>        
          </tr>
          <tr>
            <td>Розмір M</td>
            <td>82-92 см</td>
            <td>37 см</td>
            <td>40 см</td>
            <td>55 см</td>        
          </tr>
          <tr>
            <td>Розмір L</td>
            <td>90-98 см</td>
            <td>38 см</td>
            <td>41 см</td>
            <td>55 см</td>        
          </tr>
         
            </tbody>
      </table>
  </div>),
  },
 
  {
    id: 'lastivya',
    imageSrc: require('../Images/products/lastivya/lastivyaWhiteBlueMain.jpg'),
    type: 'Ластів`я',
    price: 1134,
    todayPrice: 567,
    discount: 50,
    aboutTitle:'Найоригінальніша вкорочена модель нашого магазину. Сорочка має позаду виріз для спинки, що гарно та невимушено робить акцент на талії, а також дає більший доступ до повітря для охолодження в спеку. Прямий крій сорочки відповідає сучасним трендам та разом з розрізом позаду спрощує підбір розміру.',
    aboutText: (<div><p>- Тканина: супер софт; </p>
    <p>- Made in Kharkiv. </p>
    <p>Оплата: післяплатою при отриманні або повна оплата на реквізити!</p>
    <p>Доставка: Новою поштою та Укрпоштою до відділення або кур'єром до адреси!</p></div>),
     colors: [
      '#b2d1e4', 
      '#ffffff',
      'blue',
      'green',
      'red',      
      ],
    colorsImg: [
      {
        id: 'lastivyaWhiteBlue1',
        img: require('../Images/products/lastivya/lastivyaWhiteBlueMain.jpg'),
        colorName: 'Блакитний',
      },
      {
        id: 'lastivyaWhite2',
        img: require('../Images/products/lastivya/lastivyaWhite2.jpg'),
        colorName: 'Білий',
      },
      {
        id: 'lastivyaBlue3',
        img: require('../Images/products/lastivya/lastivyaBlue3.jpg'),
        colorName: 'Синій',
      },
      {
        id: 'lastivyaWhiteBlue4',
        img: require('../Images/products/lastivya/lastivyaWhiteBlue4.jpg'),
        colorName: 'Блакитний',
      },
      {
        id: 'lastivyaWhite5',
        img: require('../Images/products/lastivya/lastivyaWhite5.jpg'),
        colorName: 'Білий',
      },
      {
        id: 'lastivyaBlue6',
        img: require('../Images/products/lastivya/lastivyaBlue6.jpg'),
        colorName: 'Синій',
      },
      {
        id: 'lastivyaGreen7',
        img: require('../Images/products/lastivya/lastivyaGreen7.jpg'),
        colorName: 'Зелений',
      },
      {
        id: 'lastivyaWhiteBlue8',
        img: require('../Images/products/lastivya/lastivyaWhiteBlue8.jpg'),
        colorName: 'Блакитний',
      },
      {
        id: 'lastivyaRed',
        img: require('../Images/products/lastivya/lastivyaRed.JPG'),
        colorName: 'Червоний',
      },
    
        ],
    sizes:["40-44","46-48"],
    sizeTable:(<div> <table className='productSize-table'>
    <thead>
      <tr>
      <th>Розмір</th>
      <th>Ваш обхват грудей</th>
      <th>Обхват грудей виробу</th>
        <th>Довжина</th>
        <th>Довжина рукава</th>
      </tr>
    </thead>
    <tbody>
    <tr>
        <td>Розмір 40-44</td>
        <td>78-90 см</td>
        <td>96 см</td>
        <td>50 см</td>
        <td>54 см</td>
        
      </tr>
      <tr>
        <td>Розмір 46-48</td>
        <td>90-102 см</td>
        <td>106 см</td>
        <td>52 см</td>
        <td>55 см</td>
      </tr>
     
        </tbody>
  </table>
  </div>),
  },

];
